<template>
  <UiPopup
    :model-value="modelValue"
    :title="checking ? 'Move to call later' : 'Still New lead'"
    :description="
      checking
        ? ''
        : 'Before you move lead to a “Call later” column, you should make a meaningful call or add a proof of result chat to leads timeline.'
    "
    :primary-button-text="checking ? '' : 'Move anyway'"
    :secondary-button-text="checking ? '' : 'Keep in New'"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <UiLoader v-if="checking" />
  </UiPopup>
</template>

<script setup lang="ts">
import type { Lead, Stage } from '@/types'
import { useUiStore } from '@/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
  stage: Stage
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})
const uiStore = useUiStore()

const checking = ref(false)
const loading = ref(false)
const canMove = ref(true)

onNuxtReady(async () => {
  checking.value = true
  try {
    const result = await useCheckLeadsAction(props.lead.id, 'call_later')
    canMove.value = (result as any)?.data?.status
    if (canMove.value) await submit()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    checking.value = false
  }
})

const submit = async () => {
  loading.value = true
  try {
    const lead = await useUpdateLead(props.lead.id, {
      id: props.lead.id,
      pipeline_stage_id: props.stage.id,
    })
    uiStore.showSnackBanner(`${props.lead.name} was moved to Call Later`)
    emits('input', lead)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
